/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable-next-line no-shadow */
/* eslint-disable-next-line react/no-array-index-key */
import React, { useState } from "react";

import { CardActions, IconButton, Menu, MenuItem, Link, Typography, Grid, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import tenantTypes from "enums/tenantTypes";
import VfSvgIcon from "../../icons/VfSvgIcon";
import DeleteAppMenuItem from "./DeleteMenuItem";
import NotesMenuItem from "./NotesMenuItem";
import UndeployAppMenuItem from "./UndeployMenuItem";
import DeployAppMenuItem from "./Deployment/DeployMenuItem";
import VisitAppMenuItem from "./VisitAppMenuItem";
import JenkinsConsoleMenuItem from "./JenkinsConsoleMenuItem";
import SecurityGroupsMenuItem from "./SecurityGroups/SecurityGroupsMenuItem";
import CompareVersionsMenuItem from "../CompareVersions/CompareVersions";
import WorkbenchDevMenuItem from "./WorkbenchDevMenuItem";

const AppActionMenu = ({ app, env, environments, tenantType, auth, envType, defaultCoreAppJob,resetSearchForm }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [originEnv, setOriginEnv] = useState(null);

  const onMenuPressed = (anchor, originEnvironment) => {
    setAnchorEl(anchor);
    setOriginEnv(originEnvironment);
  };
  const onMenuItemPressed = () => {
    setAnchorEl(null);
  };
  const stateEnvironments =
    tenantType === tenantTypes.SPAIN
      ? environments.filter(item => item.type === "dev" || item.type === "global")
      : environments;

  const coreAppJob =stateEnvironments.find(e => e._id === env.id)?.deploymentInformation?.coreAppJob;

  return (
    <Grid item xs="auto">
      <CardActions disableSpacing>
        <IconButton
          aria-label="add to favorites"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={e => onMenuPressed(e.target, env)}
          dtat-testid="AppVersion_ActionMenuBtn"
        >
          <VfSvgIcon icon="more" viewBox={24} />
        </IconButton>
        <Menu
          id="simple-menu"
          elevation={0}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onMenuItemPressed}
          data-testid={anchorEl === null ? 'closedMenuActionParent' : 'openeddMenuActionParent'}
        >
          {env &&
            !env.isGlobal &&
            (app.isDeployedToKubernetes || app.isDeployedToS3) &&
            app.status === "Deployed" &&
            tenantType !== tenantTypes.SPAIN && [
              <VisitAppMenuItem
                key={`app-actions-menu-list-${app._id}`}
                application={app}
                environment={env}
                onClick={onMenuItemPressed}
                tenantType={tenantType}
                envType={envType}
              />,
              <Divider key={`app-actions-menu-divider-${app._id}`} />,
            ]}
          {tenantType === tenantTypes.SPAIN &&
            app.status === "Deployed" && [
              <MenuItem data-testid="openDeployedApp_btn">
                <Typography variant="body2" color="secondary" onClick={onMenuItemPressed}>
                  <Link href={app.workbenchUrl} rel="noreferrer" target="_blank" variant="body2" color="secondary">
                    {`Open ${app.title}`}
                  </Link>
                </Typography>
              </MenuItem>,
              envType === "dev" && (
                <WorkbenchDevMenuItem application={app} environment={env} auth={auth} onClick={onMenuItemPressed} />
              ),
              <Divider key={`app-actions-menu-divider-${app._id}`} />,
            ]}
          <NotesMenuItem onClick={onMenuItemPressed} />
          {env && env.isGlobal && <CompareVersionsMenuItem onClick={onMenuItemPressed} app={app} />}
          {env && env.isGlobal && (
            <SecurityGroupsMenuItem application={app} environment={env} onClick={onMenuItemPressed} />
          )}
          {env &&
            env.isGlobal &&
            stateEnvironments
              .filter(environment => !environment.isGlobal)
              .sort((a, b) => a.order - b.order)
              .map(environment => {
                return (
                  <DeployAppMenuItem
                    key={`global-${app._id}-${environment._id}`}
                    application={app}
                    environment={environment}
                    originEnvironment={originEnv}
                    onPress={onMenuItemPressed}
                  />
                )            
              })}
          {env &&
            !env.isGlobal &&
            stateEnvironments
              .filter(
                environment =>
                  !environment.isGlobal &&
                  environment._id !== env._id &&
                  environment.order > env.order &&
                  app.status === "Deployed"
              )
              .sort((a, b) => a.order - b.order)
              .map(environment => {
                return (
                  <DeployAppMenuItem
                    key={`deployed-${app._id}-${environment._id}`}
                    application={app}
                    environment={environment}
                    originEnvironment={originEnv}
                    onPress={onMenuItemPressed}
                  />
                );
              })}
          {env &&
            !env.isGlobal &&
            app.buildNumber && [
              <JenkinsConsoleMenuItem
                key={`jenkins-console-menu-list-${app._id}`}
                application={app}
                coreAppJob={coreAppJob || defaultCoreAppJob}
                onClick={onMenuItemPressed}
              />,
              <Divider key={`jenkins-console-actions-menu-divider-${app._id}`} />,
            ]}

          {env && env.isGlobal && <DeleteAppMenuItem application={app} onClick={onMenuItemPressed} resetSearchForm={resetSearchForm} />}

          {env && !env.isGlobal && app.status !== "Pending" && app.status !== "Building" && (
            <UndeployAppMenuItem env={env} app={app} coreAppJob={coreAppJob} onClick={onMenuItemPressed} />
          )}
        </Menu>
      </CardActions>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    environments: state.envs.list || [],
    tenantType: state.tenants?.current?.type,
    auth: state.authentication,
    defaultCoreAppJob: state.envs.defaultCoreAppJob || "master"
  };
};

export default connect(mapStateToProps, null)(AppActionMenu);
